import Modal from 'react-bootstrap/Modal';

function ImageModal({ show, setShow, src, id, title }) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby={id}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={id}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={src} width="100%" alt="" />
      </Modal.Body>
    </Modal>
  );
}

export default ImageModal;