import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import styles from './MajorGroups.module.css';
import { useEffect, useState } from 'react';
import { retrieveMajorGroupAdmins } from '../api/BackendAPI';

function QRCodeBlock({ url, name, secretCode, comment }) {
  return (
    <Card className={styles.codeCard}>
      <div className={styles.imageContainer}>
        <Card.Img src={url} className={styles.image} />
      </div>
      <Card.Body style={{"textAlign": "center"}}>
        <p>{name}</p>
        <p>暗号: {secretCode}</p>
        <p>{comment}</p>
      </Card.Body>
    </Card>
  );
}

function QRCode({ year, college }) {

  const [fullData, setFullData] = useState(null);

  useEffect(() => {
    const getFullData = async () => {
      const admins = await retrieveMajorGroupAdmins();
      setFullData(admins);
    };
    getFullData();
  }, []);

  if (year === -1 || college === -1) {
    return (<Alert variant="info">请选择相关信息！</Alert>);
  } else if (fullData === null) {
    return (<Spinner animation="border" variant="primary" />);
  } else {
    const data = fullData.filter(entry => entry.year === year && entry.college === college);
    return (
      <Row>
        {data.map(code => (
          <Col key={code.secret_code} xs={12} sm={6} lg={3} className="mb-4">
            <QRCodeBlock url={code.url} name={code.name} secretCode={code.secret_code} comment={code.comment} />
          </Col>
        ))}
      </Row>
    );
  }
}

export default QRCode;