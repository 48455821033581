export const endpoint = "https://gtcssa.org/api/";

async function fetchJsonResource(resource) {
    let response = await fetch(`${endpoint}${resource}`, {
        method: "GET",
        redirect: "follow",
        mode: "cors",
        credentials: "include"
    });
    return await response.json();
}

/**
 * Retrieves the list of all members.
 * 
 * @param {string} year Academic year (e.g. 2021_2022);
 */
export async function retrieveMemberInfo(year) {
    return await fetchJsonResource(`member_info?year=${year ?? "2022_2023"}`);
}

/**
 * Requests a new verification email.
 * 
 * @param {string} recipientEmail Recipient's email address.
 * @param {string} reCaptchaToken Captcha token from Google reCaptcha.
 * 
 * @returns {{
 *  linkExpiration?: string|undefined,
 *  email?: string|undefined,
 *  userRemoteIp: string,
 *  message: string,
 *  error?: string|undefined
 * }}
 */
export async function requestVerificationEmail(recipientEmail, reCaptchaToken) {
    return await fetchJsonResource(`send_verification?mail=${encodeURIComponent(recipientEmail)}&recaptchaResponse=${encodeURIComponent(reCaptchaToken)}`);
}

let _cachedAuthState = null;

/**
 * Returns the current authentication state. Use this function to check if the user has permissions to access
 * email verification protected resources.
 * 
 * @returns {{ validated: boolean, validationDate?: number, expireDate?: number }}
 */
export async function retrieveAuthenticationState() {
    if (_cachedAuthState && _cachedAuthState.expireDate > Date.now()) {
        return _cachedAuthState;
    }

    let updatedAuthState = await fetchJsonResource("client_auth_state");
    _cachedAuthState = updatedAuthState;

    return updatedAuthState;
}

/**
 * Retrieves the list of QR codes for all major discussion groups. This resource is only accessible by an authenticated
 * user. Use retrieveAuthenticationState() to check if the user has permissions to access this resource.
 * 
 * @returns {Array<{
 *  year: number,
 *  college: number,
 *  url: string,
 *  name: string,
 *  secret_code: string,
 *  comment: string|null
 * }>}
 */
export async function retrieveMajorGroupAdmins() {
    return await fetchJsonResource("wechat_groups_admin");
}