import SponsorHeader from './SponsorHeader';
import SponsorProfiles from './SponsorProfiles';

/**
 * 对应 https://gtcssa.org/sponsors
 */

function Sponsors() {
  return (
    <div>
      <SponsorHeader/>
      <SponsorProfiles/>
    </div>
  );
}

export default Sponsors;