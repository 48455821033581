import styles from './Departments.module.css';
import { useParams } from 'react-router-dom';
import MemberConcise from './MemberConcise';
import Spinner from 'react-bootstrap/Spinner';

/**
 * 对应 https://gtcssa.org/executive 等
 */
function DepartmentIntro({ info }) {
  const { departmentId } = useParams();

  const IntroComponent = () => (
    <div className={styles.depart_intro_container}>
      <p className={styles.depart_intro}>
        {info[departmentId].description}
        <br/>
        <br/>
        {info[departmentId].name}主要工作：
      </p>
      <ul>
        {info[departmentId].listItems.map((listItem, i) => (
          <li key={i}>{listItem}</li>
        ))}
      </ul>
    </div>
  )

  if (info === null) {
    return (<Spinner animation="border" variant="primary" />)
  } else {
    const members = info[departmentId].members;

    return (
      <div className={styles.example}>
        <h1 className={styles.intro_title}>{info[departmentId].name}</h1>
        {info && info[departmentId].hasIntro && (<IntroComponent />)}
        {members && (
          <div className={styles.member_container}>
            {members.map((member, i) => {
              return <MemberConcise key={`${member.name}_${i}`} name={member.name} position={member.position} image={member.image}/>;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default DepartmentIntro;