import styles from './Members.module.css';
import MemberBlock from './MemberBlock';

function DepartmentBlock(props) {
    const {
        name,
        members
    } = props
    const items = members.map((member, i) =>
        <MemberBlock 
            key={`${member.name}_${i}`} 
            name={member.name}
            major={member.major}
            grade={member.grade}
            position={member.position}
            selfIntro={member.selfIntro}
            linkedin={member.linkedin}
            image={member.image}
        /> 
    )
    return (
        <div className={styles.depart_container}>
            <h2 className={styles.depart_header}>{name}</h2>
            {items}
        </div>
    )
}
export default DepartmentBlock;