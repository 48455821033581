import { useState } from 'react';
import styles from './MajorGroups.module.css';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QRCode from './QRCode';

function MajorGroups() {
  const [year, setYear] = useState(-1);
  const [college, setCollege] = useState(-1);
  return (
    <div className={styles.container}>
      <div className={`${styles.codeText} ${styles.codeTitle}`}>专业群二维码</div>
      <div className={styles.codeText} style={{whiteSpace: "pre-line"}}>
        {"你好！\n请根据你的专业加入指定专业群！\n\n操作流程: \n1. 找到你需要的专业群。\n2. 扫码加负责该群的管理员微信号，记得备注“专业群”。\n3. 好友验证通过后，将正确的暗号发给管理员。\n4. 管理员会根据群口令拉你进群。\n\n注意事项: \n1. 请不要将二维码外传，避免代写入群。\n2. 为了尽量缩减群内人员数量，避免建立二群，请不要加入其他专业群。\n3. CS专业本科生加入大群同时可以选择加入thread群，方便大家找课友。还没有declare thread的同学请先不要加thread群。\n4. 本科CS Minor的同学请不要加CS大群，不过可以根据你的minor加入下面8个thread群之一。\n\n如果有任何问题，请发邮件给技术部部长:\nyongnuo.yang@gatech.edu\n或者加微信 yongnwo \n\n感谢大家的配合，祝大家门门拿A！\n-- GTCSSA"}
      </div>
      <div className={styles.codeText} style={{marginTop: "40px"}}>
        请选择你的学位和学院以获取专业群二维码：
      </div>
      <Row style={{marginBottom: "1%"}}>
        <Col xs={12} md={3}>
          <Form.Select value={year} onChange={e => setYear(e.target.value)}>
            <option value={-1}>请选择学位</option>
            <option value={0}>本科</option>
            <option value={1}>研究生</option>
            <option value={2}>博士</option>
          </Form.Select>
        </Col>
      </Row>
      <Row style={{marginBottom: "2%"}}>
        <Col xs={12} md={3}>
          <Form.Select value={college} onChange={e => setCollege(e.target.value)}>
            <option value={-1}>请选择学院</option>
            <option value={0}>College of Business</option>
            <option value={1}>College of Computing</option>
            <option value={2}>College of Design</option>
            <option value={3}>College of Engineering</option>
            <option value={4}>College of Liberal Arts</option>
            <option value={5}>College of Science</option>
          </Form.Select>
        </Col>
      </Row>
      <QRCode year={parseInt(year)} college={parseInt(college)} />
    </div>
  );
}

export default MajorGroups;