import styles from './Sponsors.module.css';

function SponsorHeader() {
  return (
    <div className={styles.header_container}>
      <div className={styles.header_text_container}>
        <div className={styles.sponsor_title}>2021-2022年度赞助商</div>
        <div className={styles.sponsor_subtitle}>我们的金主爸爸们</div>
      </div>
    </div>
  )
}
export default SponsorHeader;