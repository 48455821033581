import styles from './Members.module.css';

function MemberBlock(props) {
  const {
    name,
    major,
    grade,
    position,
    selfIntro,
    linkedin,
    image
  } = props
  return (
    <div className={styles.profile}>
      <img className={styles.profile_pic} src={image} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/member_photos/placeholder.jpeg"}} alt="member"/>
      <div className={styles.info_container}>
        <div className={styles.info_field}><p className={styles.name}>{name}</p></div>
        <div className={styles.info_field}><p className={styles.other_info}>{position}</p></div>
        <div className={styles.info_field}><p className={styles.other_info}>{grade}</p></div>
        <div className={styles.info_field}><p className={styles.other_info}>{major}</p></div>
      </div>
      <div className={styles.intro_container}>
        <div className={styles.intro}><p>{selfIntro}</p></div>
        <div className={styles.linkedin}><a href={linkedin} target="_blank" rel="noreferrer">{linkedin}</a></div>
      </div>
  </div>
  )
}
export default MemberBlock;