import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import styles from './PastCSSA.module.css';

function PastCSSA() {
  return (
    <div>
      <h1 className={styles.depart_title}>往届CSSA信息</h1>
      <div>
        <Row className="justify-content-center">
          <Col xs={10} md={8}>
            <Table striped bordered>
              <tbody>
                <tr>
                  <td>2020-2021届</td>
                  <td><Link to="/departments?year=2020_2021">部门介绍</Link></td>
                  <td><Link to="/members?year=2020_2021">成员介绍</Link></td>
                </tr>
                <tr>
                  <td>2021-2022届</td>
                  <td><Link to="/departments?year=2021_2022">部门介绍</Link></td>
                  <td><Link to="/members?year=2021_2022">成员介绍</Link></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PastCSSA;