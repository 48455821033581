/**
 * 对应头像，名字和职位
 */
import styles from './Departments.module.css';

function MemberConcise(props) {
  const {name, position, image} = props;

  return(
    <div className={styles.profile_container}>
        <img className={styles.profile_pic} src={image} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/member_photos/placeholder.jpeg"}} alt="member"/>
        <p className={styles.member_name}>{name}</p>
        <p className={styles.member_position}>{position}</p>
    </div>
  );
}

export default MemberConcise;