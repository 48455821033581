import styles from './Members.module.css';
import MemberFull from './MemberFull';

/**
 * 对应 https://gtcssa.org/members
 */

function Members() {
  return (
    <div className={styles.example}>
      <MemberFull />
    </div>
  );
}

export default Members;