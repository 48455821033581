import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Footer.module.css';
import ImageModal from './ImageModal';

function Footer() {
  const [miniShow, setMiniShow] = useState(false);
  if (useLocation().pathname !== "/") {
    return (<div />);
  }
  return (
    <div className={styles.footer}>
      <h4>联系我们</h4>
      <Container fluid>
        <Row width="100%">
          <Col className={styles.footerCol} md={4} xs={12}>
            <a href="https://www.instagram.com/gtcssa/" target="_blank" rel="noreferrer">
              <img style={{width: "50px"}} src="/assets/ins_icon.png" alt="" />
            </a>
            <span>@gtcssa</span>
          </Col>
          <Col className={styles.footerCol} md={4} xs={12}>
            <span style={{cursor: "pointer"}} onClick={() => setMiniShow(true)}>
              <img style={{width: "50px"}} src="/assets/logo.png" alt="" />
            </span>
            <span>微信小程序</span>
          </Col>
          <Col className={styles.footerCol} md={4} xs={12}>
            <a href="mailto:gtcssa@gmail.com">
              <img style={{width: "50px"}} src="/assets/mail_icon.png" alt="" />
            </a>
            <span>gtcssa@gmail.com</span>
          </Col>
        </Row>
      </Container>
      <ImageModal show={miniShow} setShow={setMiniShow} src="/assets/mini_program.jpg" id="mini-modal" title="官方微信小程序" />
    </div>
  );
}

export default Footer;