import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Navbar from './shared_components/Navbar';
import Footer from './shared_components/Footer';
import HomePage from './home_page/HomePage';
import Departments from './departments/Departments';
import Members from './members/Members';
import Sponsors from './sponsors/Sponsors';
import Contact from './contact/Contact';
import MajorGroups from './major_groups/MajorGroups';
import { ProtectedRoute } from './shared_components/Auth';
import PastCSSA from './past_cssa/PastCSSA';

function App() {
  return (
    <HashRouter>
      <Navbar />
      <Switch>
        <Route path="/" exact><HomePage /></Route>
        <Route path="/departments"><Departments /></Route>
        <Route path="/members" exact><Members /></Route>
        <Route path="/past" exact><PastCSSA /></Route>
        <Route path="/sponsors" exact><Sponsors /></Route>
        <Route path="/contact" exact><Contact /></Route>
        <ProtectedRoute path="/groups" exact><MajorGroups /></ProtectedRoute>
      </Switch>
      <Footer />
    </HashRouter>
  );
}

export default App;