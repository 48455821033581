import { useState, createRef, useEffect } from "react";
import { Route, Redirect } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import ReCAPTCHA from 'react-google-recaptcha';
import { retrieveAuthenticationState, requestVerificationEmail } from '../api/BackendAPI';

const useAuth = () => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const fetchAuth = async () => {
      const state = await retrieveAuthenticationState();
      setAuth(state);
    };
    fetchAuth();
  }, []);

  return auth;
}

const ProtectedRoute = ({children, ...rest}) => {
  const auth = useAuth();
  if (auth === null) {
    return (<Spinner animation="border" variant="primary" />);
  }
  if (!auth.validated) {
    return (<Redirect to="/" />)
  }
  return (<Route {...rest}>{children}</Route>);
}

const isValidEmail = email => {
  const emailPattern = /\S+@\S+\.\S+/;
  return emailPattern.test(email) && email.endsWith("@gatech.edu");
}

const recaptchaRef = createRef();

const EmailVerificationModal = ({ show, setShow }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("warning");
  const [sendAvailable, setSendAvailable] = useState(true);
  const [btnText, setBtnText] = useState("提交");

  const onSubmit = async recaptchaChallengeResponse => {
    const res = await requestVerificationEmail(email, recaptchaChallengeResponse);
    setMessage(res.message);
    !res.error && setVariant("success");

    let num = 60;
    setSendAvailable(false);
    setBtnText(`${num}`);
    const timer = setInterval(() => {
      num = num - 1;
      setBtnText(`${num}`);
      if (num === 0) {
        setBtnText("提交");
        clearInterval(timer);
        setSendAvailable(true);
      }
    }, 1000);
  }

  useEffect(() => {
    email && !isValidEmail(email) && setMessage("请输入正确的GT邮箱");
    isValidEmail(email) && setMessage("");
  }, [email]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>专业群验证</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{display: "flex", flexDirection: "column"}}>
          {message && <Alert variant={variant}>{message}</Alert>}
          <Form.Control
            className="mb-3"
            placeholder="请输入你的GT邮箱"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LfBN2MaAAAAAMrqcZ6BkV0DmvXwgOFOXWeRS0_B"
            size="invisible"
            onChange={onSubmit}
          />
          <Button 
            className="g-recaptcha"
            variant="primary"
            style={{alignSelf: "end"}}
            disabled={!isValidEmail(email) || !sendAvailable}
            onClick={() => recaptchaRef.current.execute()}
          >{btnText}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { ProtectedRoute, EmailVerificationModal, useAuth };
