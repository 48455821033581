import styles from './Departments.module.css';
import { useLocation } from 'react-router-dom';
import { Link, useRouteMatch, Switch, Route } from 'react-router-dom';
import DepartmentIntro from './DepartmentIntro';
import { useState, useEffect } from 'react';
import { retrieveMemberInfo } from '../api/BackendAPI';


/**
 * 对应 https://gtcssa.org/department_intro
 */
const departments = [
  {
    name: "主席团",
    path: "executive",
  },
  {
    name: "技术部",
    path: "JiShuBu",
  },
  {
    name: "宣传部",
    path: "XuanChuanBu",
  },
  {
    name: "外联部",
    path: "WaiLianBu",
  },
  {
    name: "内联部",
    path: "NeiLianBu",
  },
  {
    name: "企划部",
    path: "QiHuaBu",
  },
  {
    name: "职业部",
    path: "ZhiYeBu",
  },
  {
    name: "财务部",
    path: "CaiWuBu",
  },
]
function Departments() {

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const year = query.get("year");

  const [info, setInfo] = useState(null);
  useEffect(() => {
    const getInfo = async () => {
      const info = await retrieveMemberInfo(year);
      setInfo(info);
    };
    getInfo();
  }, [year]);

  let { path } = useRouteMatch();
  
  const DepartmentPage = () => (
    <div>
      <h1 className={styles.depart_title}>部门介绍</h1>
      <div className={styles.depart_separator}></div>
      <ul className={styles.depart_list_item}>
      {departments.map((department) => (
        <li key={department.name} className={styles.depart}>
          <Link to={`${path}/${department.path}?year=${year}`} className={styles.depart_link}>{department.name}</Link>
        </li>
      ))}
      </ul>
    </div>
  )

  return (
    <div className={styles.example}>
      <Switch>
        <Route path={`${path}/:departmentId`} exact render={() => <DepartmentIntro info={info} />}></Route>
        <Route component={DepartmentPage}></Route>
      </Switch>
    </div>
  );
}

export default Departments;