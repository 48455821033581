import styles from './Contact.module.css';

/**
 * 对应 https://gtcssa.org/contact_me
 */
function Contact() {
  return (
    <div>
      <div className={styles.contact_container}>
          <div className={styles.contact_upper_page}>
            <div className={styles.title_wrapper}><h1 className={styles.page_header}>  Contact   </h1> </div>
                      
            <img className={styles.logo} src="/assets/logo.png" alt="logo"/>       
            <div className={styles.notification}> <p className={styles.name}>GTCSSA</p></div>               
        </div>
        
        <div className={styles.line}>        </div>

        <div className={styles.info_container}>
          <p className={styles.words}>
            你好！我们是GTCSSA 中国学生学者联谊会。<br/> 
            <br/> 
            有任何问题欢迎用以下方式联系我们~<br/> 
            小助手微信号: GTCSSA<br/> 
            官方邮箱: gtcssa2018@gmail.com<br/> 
            <br/> 
            有网站相关问题也可以直接向技术部部长反馈<br/> 
            姓名: 杨咏诺<br/> 
            邮箱: yongnuo.yang@gatech.edu  <br/> 
            <br/>
            </p>
        </div>
        <div className={styles.photo_links}>
          <div className={styles.photo_wrapper}>
            <img src="/assets/Instagram_adjusted.png" alt="ins"/><p className={styles.notation}> Instagram链接</p>
          </div>
           
          <div className={styles.photo_wrapper}>
            <img src="/assets/wechat.jpg" alt="wechat"/><p className={styles.notation}>CSSA小助手微信</p> 
          </div>

          <div className={styles.photo_wrapper}>
            <img src="/assets/CSSAMiniprogram.jpg" alt="miniprogram"/><p className={styles.notation}> CSSA小程序</p>
          </div>

          <div className={styles.photo_wrapper}>
            <img src="/assets/Wechat_official_account.png" alt="wechat official account"/> <p className={styles.notation}>CSSA公众号</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;