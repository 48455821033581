import styles from './Sponsors.module.css';
import { VERSION } from '../api/utils';

function SponsorProfiles() {
  return (
    <div className={styles.sponsor_container}>
      {
        [...Array(15)].map((e, i) => {
          const image = `/assets/sponsor_logos/${VERSION}_sponsor_${i}.png`;
          return (
            <div className={styles.profile_container} key={i}>
              <img className={styles.profile_pic} src={image} alt="SponsorProfile"/>
            </div>
          )
        })
      }
    </div>
  )
}
export default SponsorProfiles;