import DepartmentBlock from "./DepartmemtBlock";
import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { retrieveMemberInfo } from '../api/BackendAPI';
import { useLocation } from 'react-router-dom';
/**
 * 对应 https://gtcssa.org/members 里的每一行 (名字 专业 年级 职位 自我介绍 LinkedIn 照片)
 */

function MemberFull() {
  var departments = []

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const year = query.get("year");

  const [info, setFullData] = useState(null);
  useEffect(() => {
    const getFullData = async () => {
      const member_info = await retrieveMemberInfo(year);
      setFullData(member_info);
    };
    getFullData();
  }, [year]);
  if (info === null) {
    return (<Spinner animation="border" variant="primary" />)
  } else {
    Object.keys(info).forEach((key) => {
      departments.push(info[key])
    })
    const items = departments.map((depart, i) =>
      <DepartmentBlock 
            key={`${depart.name}_${i}`}
            name={depart.name}
            members={depart.members}/> 
    )
    return(
      <div>
        {items}
      </div>
    );
  }
}

export default MemberFull;