import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillWechat, AiFillInstagram } from 'react-icons/ai';
import NavContainer from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styles from './Navbar.module.css';
import ImageModal from './ImageModal';
import { useAuth, EmailVerificationModal } from './Auth';

function Navbar() {

  const [insShow, setInsShow] = useState(false);
  const [wcShow, setWcShow] = useState(false);
  const [emailShow, setEmailShow] = useState(false);
  const auth = useAuth();

  return (
    <NavContainer id={styles.navbar} expand="lg">
      <Container>
        <NavContainer.Brand as={Link} to="/">
          <img src="/assets/logo.png" width="50" height="50" alt="" loading="lazy" />
        </NavContainer.Brand>
        <NavContainer.Toggle aria-controls="navbarContent" />
        <NavContainer.Collapse id="navbarContent">
          <Nav className="me-auto">
            <NavDropdown id={styles.navDropdown} title="关于CSSA">
              <NavDropdown.Item as={Link} to="/departments?year=2022_2023">部门介绍</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/members?year=2022_2023">成员介绍</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/past">往届CSSA信息</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => setInsShow(true)}><AiFillInstagram style={{verticalAlign: "middle"}} /> Instagram</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setWcShow(true)}><AiFillWechat style={{verticalAlign: "middle"}} /> 微信</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className={styles.navLink} as={Link} to="/sponsors">赞助商</Nav.Link>
            <Nav.Link className={styles.navLink} as={Link} to="/contact">联系我们</Nav.Link>
            {auth?.validated ? 
              <Nav.Link className={styles.navLink} as={Link} to="/groups">专业群</Nav.Link> :
              <Nav.Link className={styles.navLink} onClick={() => setEmailShow(true)}>专业群</Nav.Link>
            }
          </Nav>
        </NavContainer.Collapse>
      </Container>
      <EmailVerificationModal show={emailShow} setShow={setEmailShow} />
      <ImageModal show={insShow} setShow={setInsShow} src="/assets/ins.png" id="ins-modal" title="Instagram" />
      <ImageModal show={wcShow} setShow={setWcShow} src="/assets/wechat.jpg" id="wechat-modal" title="CSSA小助手微信" />
    </NavContainer>
  );
}

export default Navbar;