import styles from './HomePage.module.css';
import { Carousel } from 'react-bootstrap';
import { CarouselItem } from 'react-bootstrap';
import { useState } from 'react';
import { FaCode } from "react-icons/fa";
import { FaMoneyBill } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";
import { FaCameraRetro } from "react-icons/fa";
import { FaTrophy } from "react-icons/fa";
import { VERSION } from '../api/utils';

/**
 * 对应 https://gtcssa.org/
 */
function HomePage() {
  const [carouselIndex, setCarouselIndex] = useState(0);
  return (
    <div>
      <div style={{backgroundImage: `url(/assets/${VERSION}_home_${carouselIndex}.jpg)`}} className={styles.carouselContainer}></div>
      <Carousel activeIndex={carouselIndex} onSelect={(selected) => setCarouselIndex(selected)} variant="dark">
        {[0, 1, 2, 3].map(index => (
          <CarouselItem key={index}>
            <img
              className={styles.carouselImage}
              src={`/assets/${VERSION}_home_${index}.jpg`}
              alt={`Slide ${index}`}
            />
          </CarouselItem>
        ))}
      </Carousel>

      <div style={{margin: "0% 18%"}}>
        <div className={styles.title}>
          组织介绍
        </div>

        <div className={styles.intro}>
          GTCSSA是佐治亚理工注册的官方中国学生会，
          受到休斯顿中国领事馆的支持与承认。 
          我们旨在增强中国留学生及学者间的沟通交流；提供学业、职业等方面各种渠道的资源；
          丰富大家的业余生活；为学生会成员提供福利；代表中国学生发声；弘扬中国文化。
        </div>
      </div>

      <div className={styles.title}>
        部门介绍
      </div>

      <div style={{margin: "0% 9% 4% 9%"}}>
        <div className={styles.real_depart_container}>
          <div className="row no-gutters dep-row">
            <div className={`col-2 d-none d-md-flex ${styles.depBox} ${styles.borderLeft}`}>
              <FaCode color="gray" size={70}/>
            </div>
            <div className={`col-md-8 col-10 ${styles.depBox}`} style={{backgroundColor: 'rgb(224, 217, 217)'}}>
              <div className={styles.depBoxText}>
                <b>技术部</b>负责网站和小程序的开发和维护,以及给其他部门提供技术上的支持。
              </div>
            </div>
            <div className={`col-2 ${styles.depBox} ${styles.borderRight}`}>
              <h1 className={styles.h1show}>01</h1>
            </div>
          </div>
          <div className="row no-gutters dep-row">
            <div className={`col-2 d-none d-md-flex ${styles.depBox} ${styles.borderLeft}`}>
              <FaMoneyBill color="gray" size={70}/>
            </div>
            <div className={`col-2 ${styles.depBox} ${styles.borderLeft}`}>
              <h1 className={styles.h1show}>02</h1>
            </div>
            <div className={`col-md-8 col-10 ${styles.depBox}`} style={{backgroundColor: 'rgb(254, 233, 180)'}}>
              <div className={styles.depBoxText}>
                <b>财务部</b>负责开支报销,服务赞助商进行转账,为CSSA提供坚实的财务保证。
              </div>
            </div>
          </div>
          <div className="row no-gutters dep-row">
            <div className={`col-2 d-none d-md-flex ${styles.depBox} ${styles.borderLeft}`}>
              <FaHeart color="gray" size={70}/>
            </div>
            <div className={`col-md-8 col-10 ${styles.depBox}`} style={{backgroundColor: 'rgb(224, 217, 217)'}}>
              <div className={styles.depBoxText}>
                <b>企划部</b>负责学生会各种活动策划与实施,举办大大小小的文娱活动。
              </div>
            </div>
            <div className={`col-2 ${styles.depBox} ${styles.borderRight}`}>
              <h1 className={styles.h1show}>03</h1>
            </div>
          </div>
          <div className="row no-gutters dep-row">
            <div className={`col-2 d-none d-md-flex ${styles.depBox} ${styles.borderLeft}`}>
              <FaUser color="gray" size={70}/>
            </div>
            <div className={`col-2 ${styles.depBox} ${styles.borderLeft}`}>
              <h1 className={styles.h1show}>04</h1>
            </div>
            <div className={`col-md-8 col-10 ${styles.depBox}`} style={{backgroundColor: 'rgb(254, 233, 180)'}}>
              <div className={styles.depBoxText}>
                <b>内联部</b>负责组织学联大会,人事管理等工作,是把各部门联合起来的强力胶
              </div>
            </div>
          </div>
          <div className="row no-gutters dep-row">
            <div className={`col-2 d-none d-md-flex ${styles.depBox} ${styles.borderLeft}`}>
              <FaAddressBook color="gray" size={70}/>
            </div>
            <div className={`col-md-8 col-10 ${styles.depBox}`} style={{backgroundColor: 'rgb(224, 217, 217)'}}>
              <div className={styles.depBoxText}>
                <b>外联部</b>负责与企业,NGO组织,兄弟学联等建立良好关系,以及和赞助商沟通签订合同。
              </div>
            </div>
            <div className={`col-2 ${styles.depBox} ${styles.borderRight}`}>
              <h1 className={styles.h1show}>05</h1>
            </div>
          </div>
          <div className="row no-gutters dep-row">
            <div className={`col-2 d-none d-md-flex ${styles.depBox} ${styles.borderLeft}`}>
              <FaCameraRetro color="gray" size={70}/>
            </div>
            <div className={`col-2 ${styles.depBox} ${styles.borderLeft}`}>
              <h1 className={styles.h1show}>06</h1>
            </div>
            <div className={`col-md-8 col-10 ${styles.depBox}`} style={{backgroundColor: 'rgb(254, 233, 180)'}}>
              <div className={styles.depBoxText}>
                <b>宣传部</b>负责设计海报周边，记录并剪辑图片视频，以及在多个平台进行推送。
              </div>
            </div>
          </div>
          <div className="row no-gutters dep-row">
            <div className={`col-2 d-none d-md-flex ${styles.depBox} ${styles.borderLeft}`} style={{borderBottomStyle: 'solid'}}>
              <FaTrophy color="gray" size={70}/>
            </div>
            <div className={`col-md-8 col-10 ${styles.depBox}`} style={{backgroundColor: 'rgb(224, 217, 217)'}}>
              <div className={styles.depBoxText}>
                <b>职业部</b>负责和国内企业合作宣传校招，以及开展求职相关的讲座和交流会。
              </div>
            </div>
            <div className={`col-2 ${styles.depBox} ${styles.borderRight}`} style={{borderBottomStyle: 'solid'}}>
              <h1 className={styles.h1show}>07</h1>
            </div>
          </div>
        </div>
      </div>
  
  
  </div>
  );
}

export default HomePage;